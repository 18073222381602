import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import NoDataImage from "../assets/empty-box.png";

const NoData = () => {
  return (
    <Box>
      <Image src={NoDataImage} alt="no data" w="100px" mx="auto" />
      <Text color="gray" mt={4} textAlign="center">
        No existing calendars found
      </Text>
    </Box>
  );
};

export default NoData;
