import ReactDOM from "react-dom/client";
// Chakra UI
import { ChakraProvider } from "@chakra-ui/react";
// Theme
import theme from "./configs/theme";
// eslint-disable-next-line import/no-cycle
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ChakraProvider resetCSS theme={theme}>
    <App />
  </ChakraProvider>,
);
