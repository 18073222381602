import CONSTANTS from "../configs/constatnts";

export const initiateMicrosoftAuthRequest = () => {
  const clientId = `${process.env.REACT_APP_MS_CLIENT_ID}`;
  const redirectUri = CONSTANTS.REDIRECT_URI;
  const authorizationUrl = CONSTANTS.MICROSOFT.AUTHORIZATION_URL;
  const scope =
    "openid profile email Calendars.Read Calendars.ReadWrite offline_access User.Read";
  const state = "RANDOM_STATE";
  const authorizationRequest = `${authorizationUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}&prompt=select_account`;

  window.location.href = authorizationRequest;
};

export const initiateGoogleAuthRequest = () => {
  const rootUrl = CONSTANTS.GOOGLE.AUTHORIZATION_URL;
  const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

  const options = {
    redirect_uri: CONSTANTS.REDIRECT_URI,
    client_id: clientId,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: ["https://www.googleapis.com/auth/calendar.readonly"].join(" "),
  };

  const qs = new URLSearchParams(options);

  window.location.href = `${rootUrl}?${qs.toString()}`;
};
