import { Container } from "@chakra-ui/react";
import AddCalendar from "./components/AddCalendar";
import ExistingCalendars from "./components/ExistingCalendars";
import CalendarAction from "./components/CalendarAction";
import NewCalendars from "./components/NewCalendars";

export default function Home() {
  return (
    <Container p={6}>
      <ExistingCalendars />
      <NewCalendars />
      <AddCalendar />
      <CalendarAction />
    </Container>
  );
}
