import { Box, Flex, Skeleton, Stack } from "@chakra-ui/react";

const CalendarListSkeleton = () => {
  return (
    <Stack spacing="30px">
      {Array(2)
        .fill(null)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flex gap={4} key={index}>
            <Skeleton height="25px" width="25px" />
            <Box flexGrow={1}>
              <Skeleton height="20px" width="auto" />
              <Skeleton height="8px" width="80px" mt={3} />
            </Box>
          </Flex>
        ))}
    </Stack>
  );
};

export default CalendarListSkeleton;
