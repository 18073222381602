import { useEffect, useState } from "react";
import { IPageLayoutProps } from "../types/commonTypes";
import NavigationBar from "./NavigationBar";
import CONSTANTS from "../configs/constatnts";

const PageLayout: React.FC<IPageLayoutProps> = ({ children }) => {
  const [source, setSource] = useState<string>("");
  useEffect(() => {
    const storedSource = sessionStorage.getItem(CONSTANTS.KEYS.SOURCE);
    if (storedSource) {
      setSource(storedSource);
    }
  }, []);
  return (
    <>
      {(!source || source === "teams") && <NavigationBar />}
      {children}
    </>
  );
};

export default PageLayout;
