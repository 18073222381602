import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// icons
import { Plus } from "react-feather";
// chakra ui
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  VStack,
  // StackDivider,
  Box,
  Image,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
// msal
import axios from "axios";
// images
import MicrosoftLogo from "../../../assets/microsoft.svg";
import GoogleLogo from "../../../assets/google.svg";
import {
  initiateMicrosoftAuthRequest,
  initiateGoogleAuthRequest,
} from "../../../services/authServices";
import {
  IGoogleCalendar,
  INewCalendar,
  IOutlookCalendar,
} from "../../../types/calendarTypes";
import { CalendarContext } from "../../../context/CalendarContext";
import CONSTANTS from "../../../configs/constatnts";
import { getApiUrl, saveNewCalendars } from "../../../services/commonServices";

const AddCalendar = () => {
  const { setNewCalendars } = useContext(CalendarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCalendarFetchError = () => {
    toast({
      title: "Failed to fetch calendars",
      description: "Could,'t fetch your calendars. Please try again",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const fetchOutlookCalendars = (accessToken: string, refreshToken: string) => {
    const graphApiEndpoint = CONSTANTS.MICROSOFT.CALENDAR_END_POINT;
    const infoApiEndPoint = CONSTANTS.MICROSOFT.INFO_END_POINT;

    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get(infoApiEndPoint, headers)
      .then((res) => {
        const userEmail = res.data.mail;
        axios
          .get(graphApiEndpoint, headers)
          .then((response) => {
            if (response.status === CONSTANTS.API_RESPONSE.SUCCESS) {
              const savedNewCalendars = sessionStorage.getItem(
                CONSTANTS.KEYS.NEW_CALENDARS,
              );
              const savedNewCalendarsValue = savedNewCalendars
                ? JSON.parse(savedNewCalendars)
                : [];

              const savedExistingCalendars = sessionStorage.getItem(
                CONSTANTS.KEYS.EXISTING_CALENDARS,
              );
              const savedExistingCalendarsValue = savedExistingCalendars
                ? JSON.parse(savedExistingCalendars)
                : [];

              const totalCalendarsPresent = [
                ...savedExistingCalendarsValue,
                ...savedNewCalendarsValue,
              ];

              const newCalendarsAddedByUser =
                response?.data?.value?.filter(
                  (calendar: IOutlookCalendar) =>
                    !totalCalendarsPresent.some(
                      (savedCalendar: INewCalendar) =>
                        savedCalendar.calendar_id === calendar.id,
                    ),
                ) ?? [];

              const userCalendars = newCalendarsAddedByUser.map(
                (calendar: IOutlookCalendar) => {
                  return {
                    calendar_id: calendar.id,
                    subscription_state: "enabled",
                    credentials: {
                      refresh_token: refreshToken,
                    },
                    calendar_data: {
                      name: calendar.name,
                      source: userEmail,
                      isShared: `${!calendar.owner?.name}`,
                    },
                    type: "outlook",
                  };
                },
              );

              const updatedCalendarList = [
                ...savedNewCalendarsValue,
                ...userCalendars,
              ];
              setNewCalendars(updatedCalendarList);
              saveNewCalendars(updatedCalendarList);
            } else {
              throw new Error(
                `Calendar fetch failed with status: ${response.status}`,
              );
            }
          })
          .catch((error) => {
            console.log(error);
            handleCalendarFetchError();
          });
      })
      .catch((err) => {
        console.log(err);
        handleCalendarFetchError();
      });
  };

  const handleTokenFetchError = () => {
    toast({
      title: "Failed to add account",
      description: "Could,'t fetch your account. Please try again",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const fetchGoogleCalendars = (accessToken: string, refreshToken: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const calendarApiUrl = CONSTANTS.GOOGLE.CALENDAR_END_POINT;

    axios
      .get(calendarApiUrl, config)
      .then((response) => {
        const calendars = response.data.items;
        console.log("User Calendars:", calendars);
        const primaryCalendarEmail = calendars.filter(
          (calendar: IGoogleCalendar) => calendar.primary === true,
        )[0]?.id;
        console.log("primary cal email", primaryCalendarEmail);
        if (response.status === CONSTANTS.API_RESPONSE.SUCCESS) {
          const savedNewCalendars = sessionStorage.getItem(
            CONSTANTS.KEYS.NEW_CALENDARS,
          );
          const savedNewCalendarsValue = savedNewCalendars
            ? JSON.parse(savedNewCalendars)
            : [];

          const savedExistingCalendars = sessionStorage.getItem(
            CONSTANTS.KEYS.EXISTING_CALENDARS,
          );

          const savedExistingCalendarsValue = savedExistingCalendars
            ? JSON.parse(savedExistingCalendars)
            : [];

          const totalCalendarsPresent = [
            ...savedExistingCalendarsValue,
            ...savedNewCalendarsValue,
          ];

          const newCalendarsAddedByUser =
            response?.data?.items?.filter(
              (calendar: IGoogleCalendar) =>
                !totalCalendarsPresent.some(
                  (savedCalendar: INewCalendar) =>
                    savedCalendar.calendar_id === calendar.id,
                ),
            ) ?? [];

          const userCalendars = newCalendarsAddedByUser.map((calendar: any) => {
            return {
              calendar_id: calendar.id,
              subscription_state: "enabled",
              credentials: {
                refresh_token: refreshToken,
              },
              calendar_data: {
                name: calendar.summary,
                source: primaryCalendarEmail ?? "",
              },
              type: "google",
            };
          });

          const updatedCalendarList = [
            ...savedNewCalendarsValue,
            ...userCalendars,
          ];
          setNewCalendars(updatedCalendarList);
          saveNewCalendars(updatedCalendarList);
        } else {
          throw new Error(
            `Calendar fetch failed with status: ${response.status}`,
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching calendars:", error.response.data);
        handleCalendarFetchError();
      });
  };

  const handleUnauthorizedAccess = () => {
    sessionStorage.clear();
    navigate("/unauthorized");
    toast({
      title: "Unauthorized Access",
      description:
        "Your access is invalid or expired. Please reopen this page from your app to continue",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const fetchGoogleToken = (code: string) => {
    setLoading(true);
    navigate("/home");

    const apiUrl = getApiUrl(CONSTANTS.TWEELIN_API.FETCH_GOOGLE_TOKEN);
    const tokenValue = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);

    const requestBody = {
      redirectUri: CONSTANTS.REDIRECT_URI,
      code,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    axios
      .post(apiUrl, requestBody, {
        headers,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { access_token, refresh_token } = response.data;
          fetchGoogleCalendars(access_token, refresh_token);
        } else {
          throw new Error(`Token fetch failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error("Token fetch error:", error);
        if (error?.response?.status === CONSTANTS.API_RESPONSE.UNAUTHORIZED) {
          handleUnauthorizedAccess();
          return;
        }
        handleTokenFetchError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMSToken = (code: string) => {
    setLoading(true);
    navigate("/home");

    const apiUrl = getApiUrl(CONSTANTS.TWEELIN_API.FETCH_MICROSOFT_TOKEN);
    const tokenValue = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);

    const requestBody = {
      redirectUri: CONSTANTS.REDIRECT_URI,
      code,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    axios
      .post(apiUrl, requestBody, {
        headers,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { access_token, refresh_token } = response.data;
          fetchOutlookCalendars(access_token, refresh_token);
        } else {
          throw new Error(`Token fetch failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error("Token fetch error:", error);
        if (error.response.status === CONSTANTS.API_RESPONSE.UNAUTHORIZED) {
          handleUnauthorizedAccess();
          return;
        }
        handleTokenFetchError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMicrosoftLoginRequest = () => {
    initiateMicrosoftAuthRequest();
    sessionStorage.setItem(CONSTANTS.KEYS.CURRENT_CALENDAR, "microsoft");
  };

  const handleGoogleLoginRequest = () => {
    initiateGoogleAuthRequest();
    sessionStorage.setItem(CONSTANTS.KEYS.CURRENT_CALENDAR, "google");
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      const currentChosenCalendar = sessionStorage.getItem(
        CONSTANTS.KEYS.CURRENT_CALENDAR,
      );
      console.log(currentChosenCalendar);
      if (currentChosenCalendar === "microsoft") {
        console.log("fetch ms token");
        fetchMSToken(code);
      } else if (currentChosenCalendar === "google") {
        console.log("fetch googl token");
        fetchGoogleToken(code);
      }
    }
  }, []);

  return (
    <>
      <Button
        leftIcon={<Plus />}
        colorScheme="brand"
        variant="ghost"
        onClick={onOpen}
        isLoading={loading}
        loadingText="Adding you calendars"
        spinnerPlacement="end"
      >
        Add Calendar
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", lg: "md" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Provider</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Box
                role="button"
                h="50px"
                as="button"
                px={4}
                onClick={() => handleMicrosoftLoginRequest()}
              >
                <Flex gap={5}>
                  <Image
                    boxSize="25px"
                    objectFit="cover"
                    src={MicrosoftLogo}
                    alt="microsoft logo"
                  />
                  <Text>Microsoft</Text>
                </Flex>
              </Box>
              <Box
                role="button"
                h="50px"
                as="button"
                px={4}
                onClick={() => handleGoogleLoginRequest()}
              >
                <Flex gap={5}>
                  <Image
                    boxSize="25px"
                    objectFit="cover"
                    src={GoogleLogo}
                    alt="microsoft logo"
                  />
                  <Text>Google</Text>
                </Flex>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="link">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCalendar;
