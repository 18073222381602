import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#F7CAC9",
      200: "#E59C91",
      300: "#D37A73",
      400: "#C15855",
      500: "#D31805",
      600: "#B70F00",
      700: "#A10000",
      800: "#850000",
      900: "#690000",
    },
    secondary: {
      500: "#868686",
    },
  },
});

export default theme;
