import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./styles/main.css";
import PageLayout from "./layout/PageLayout";
import { CalendarContextProvider } from "./context/CalendarContext";
import Validation from "./pages/Validation/Validation";
import Home from "./pages/Home/Home";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import Protected from "./utils/Protected";
import PageNotFound from "./pages/Invalid/PageNotFound";
import Disclosure from "./pages/Disclosure/Disclosure";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Validation />,
    },
    {
      path: "/home",
      element: <Protected Component={Home} />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/disclosure",
      element: <Disclosure />,
    },
    {
      path: "/404",
      element: <PageNotFound />,
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
  ]);

  return (
    <CalendarContextProvider>
      <PageLayout>
        <RouterProvider router={router} />
      </PageLayout>
    </CalendarContextProvider>
  );
}

export default App;
