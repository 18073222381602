import { Button, Container, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../../assets/404.svg";

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/home");
  };

  return (
    <Container
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      pt={8}
    >
      <Image
        maxWidth="400px"
        objectFit="cover"
        src={NotFoundImage}
        alt="Page not found"
      />
      <Text fontSize="lg" textAlign="center" mt={6}>
        Uh oh, we can&apos;t seem to find the page you&apos;re looking for. Try
        going back to{" "}
        <Button colorScheme="brand" onClick={navigateToHome} variant="link">
          home
        </Button>{" "}
        or explore more about tweelin{" "}
        <Link color="brand.500" href="https://tweelin.com/">
          here
        </Link>
        .
      </Text>
      <Button colorScheme="brand" size="lg" mt={4} onClick={navigateToHome}>
        Back to home
      </Button>
    </Container>
  );
};

export default PageNotFound;
