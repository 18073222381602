import { useMsal } from "@azure/msal-react";
import { Box, Container, Link, Text } from "@chakra-ui/react";
import bg from "../../assets/tweelin-bg.webp";

const Disclosure = () => {
  const { instance } = useMsal();
  instance
    .handleRedirectPromise()
    .then((response) => console.log(response))
    .catch((err) => console.log(err));

  console.log(window.location);
  return (
    <Box>
      <Box
        style={{ backgroundImage: `url(${bg})` }}
        backgroundSize="cover"
        py={24}
        px={{ base: 4, md: 12 }}
        textAlign={{ md: "center" }}
      >
        <Text
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight="bold"
          color="whitesmoke"
        >
          Google API Services Usage Disclosure
        </Text>
      </Box>
      <Container maxW="container.md" py={12}>
        <Text color="gray.500" fontSize={{ md: "lg" }}>
          Tweelin&apos;s use and transfer of information received from Google
          APIs to any other app will adhere to{" "}
          <Link
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            color="brand.500"
          >
            Google API Services User Data Policy
          </Link>
          , including the{" "}
          <Link
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            color="brand.500"
          >
            Limited Use
          </Link>{" "}
          requirements.
        </Text>
      </Container>
    </Box>
  );
};

export default Disclosure;
