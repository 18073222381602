import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../../configs/constatnts";
import ValidationInProgress from "../../components/ValidationInProgress";
import { CalendarContext } from "../../context/CalendarContext";
import { getApiUrl } from "../../services/commonServices";

const Validation = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { setSavedExistingCalendars, setExistingCalendars } =
    useContext(CalendarContext);

  const handleTokenValidationSuccess = () => {
    navigate("/home");
  };

  const handleTokenValidationFailure = () => {
    navigate("/unauthorized");
  };

  const validateToken = (tokenValue: string) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    let apiUrl = getApiUrl(CONSTANTS.TWEELIN_API.CALENDAR);

    if (sessionStorage.getItem(CONSTANTS.KEYS.SOURCE) === "teams") {
      const userEmail = sessionStorage.getItem(CONSTANTS.KEYS.USER_EMAIL);
      apiUrl += `?account=${userEmail}`;
    }

    axios
      .get(apiUrl, {
        headers,
      })
      .then((response) => {
        if (response.status === CONSTANTS.API_RESPONSE.SUCCESS) {
          // TODO: Add logic to check for unauthorized access and then proceed further

          const userExistingCalendars = response.data.data;
          setSavedExistingCalendars(userExistingCalendars);
          setExistingCalendars(userExistingCalendars);

          sessionStorage.setItem(CONSTANTS.KEYS.USER_ID_TOKEN, tokenValue);
          handleTokenValidationSuccess();
          return;
        }

        throw Error("Couldn't authorize user.");
      })
      .catch((err) => {
        console.log(err);
        handleTokenValidationFailure();
      });
  };

  // const getTokenValue = () => {
  //   const tokenParam = new URLSearchParams(hash.substring(1));
  //   const tokenValue = tokenParam?.get("token");

  //   return tokenValue;
  // };

  const fetchToken = () => {
    try {
      const tokenParam = new URLSearchParams(hash.substring(1));
      const tokenValue = tokenParam.get("token") || "";
      const tokenSource = tokenParam.get("source") || "";
      const userEmail = tokenParam.get("email") || "";

      sessionStorage.setItem(CONSTANTS.KEYS.SOURCE, tokenSource);
      sessionStorage.setItem(CONSTANTS.KEYS.USER_EMAIL, userEmail);

      if (!tokenValue) {
        handleTokenValidationFailure();
      } else {
        validateToken(tokenValue);
      }
    } catch (error) {
      console.error("Error while processing token:", error);
      handleTokenValidationFailure();
    }
  };

  useEffect(() => {
    // TODO: First scene is token is not in url nor in storage, 2nd - not in url but in storage, 3rd - storage have one but a new token comes through url

    const existingUser = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    // const currentUser = getTokenValue();

    if (existingUser) {
      handleTokenValidationSuccess();
      return;
    }

    fetchToken();
  }, [hash]);

  return (
    <div>
      <ValidationInProgress />
    </div>
  );
};

export default Validation;
