import { Box, Progress, Text } from "@chakra-ui/react";

const ValidationInProgress = () => {
  return (
    <Box>
      <Progress size="xs" isIndeterminate colorScheme="brand" />
      <Text textAlign="center" mt={4} size="lg">
        Validating user ...
      </Text>
    </Box>
  );
};

export default ValidationInProgress;
