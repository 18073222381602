import React from "react";
import { Box, Image } from "@chakra-ui/react";
import logo from "../assets/logo.png";

const NavigationBar = () => {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      w="100"
      bg="white"
      py={8}
      zIndex={9}
    >
      <Image objectFit="cover" src={logo} alt="tweelin" w={180} mx="auto" />
    </Box>
  );
};

export default NavigationBar;
