import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../configs/constatnts";
import { CalendarContext } from "../../../context/CalendarContext";
import checkForCalendarChanges from "../../../services/compareCalendar";
import { ICalendar } from "../../../types/calendarTypes";
import CalendarListSkeleton from "../../../components/CalendarListSkeleton";
import NoData from "../../../components/NoData";
import { ICheckBoxModal, IDeleteAlertModal } from "../../../types/uiTypes";
import CheckBoxModal from "../../../components/CheckBoxModal";
import DeleteAlert from "../../../components/DeleteAlert";
import outlookIcon from "../../../assets/outlook.png";
import googleIcon from "../../../assets/google.svg";
import { getApiUrl } from "../../../services/commonServices";

const ExistingCalendars = () => {
  const {
    savedExistingCalendars,
    existingCalendars,
    setExistingCalendars,
    setSavedExistingCalendars,
    setCalendarEdited,
    calendarSaved,
    deletedCalendars,
    setDeletedCalendars,
    setEnabledCalendars,
    setDisabledCalendars,
  } = useContext(CalendarContext);
  const [checkBoxModal, setCheckBoxModal] = useState<ICheckBoxModal>({
    visibility: false,
    calendar: null,
  });
  const [deleteAlert, setDeleteAlert] = useState<IDeleteAlertModal>({
    visibility: false,
    calendar: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showLimit, setShowLimit] = useState(5);
  const [sourceMobile, setSourceMobile] = useState<boolean>(false);
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const toast = useToast();

  const handleUnauthorizedAccess = () => {
    sessionStorage.clear();
    navigate("/unauthorized");
    toast({
      title: "Unauthorized Access",
      description:
        "Your access is invalid or expired. Please reopen this page from your app to continue",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleFetchCalendarFailure = () => {
    toast({
      title: "Failed to fetch calendars",
      description:
        "Something went wrong while fetching your calendars. Please try again.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const viewLessCalendars = () => {
    setShowLimit(5);
  };

  const viewMoreCalendars = () => {
    setShowLimit(existingCalendars.length);
  };

  const fetchCalendars = () => {
    setLoading(true);
    const tokenValue = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    const apiUrl = getApiUrl(CONSTANTS.TWEELIN_API.CALENDAR);

    axios
      .get(apiUrl, {
        headers,
      })
      .then((response) => {
        console.log(response);
        if (response.status === CONSTANTS.API_RESPONSE.SUCCESS) {
          const userExistingCalendars = response.data.data ?? [];
          setSavedExistingCalendars(userExistingCalendars);
          setExistingCalendars(userExistingCalendars);
          sessionStorage.setItem(
            CONSTANTS.KEYS.EXISTING_CALENDARS,
            JSON.stringify(userExistingCalendars),
          );
          return;
        }

        throw Error("Couldn't authorize user.");
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === CONSTANTS.API_RESPONSE.UNAUTHORIZED) {
          handleUnauthorizedAccess();
          return;
        }
        handleFetchCalendarFailure();
      })
      .finally(() => setLoading(false));
  };

  const subscriptionChange = (currentCalendars: Array<ICalendar>) => {
    const enabledArray: Array<any> = [];
    const disabledArray: Array<any> = [];

    currentCalendars.forEach((newItem) => {
      const oldItem = savedExistingCalendars.find(
        (item) => item.calendar_id === newItem.calendar_id,
      );
      if (
        oldItem &&
        oldItem.subscription_state !== newItem.subscription_state
      ) {
        if (newItem.subscription_state === "enabled") {
          enabledArray.push(newItem);
        } else if (newItem.subscription_state === "disabled") {
          disabledArray.push(newItem);
        }
      }
    });

    setEnabledCalendars(enabledArray);
    setDisabledCalendars(disabledArray);
  };

  const handleChanges = (currCalendars: ICalendar[]) => {
    const enableEdit = checkForCalendarChanges(
      savedExistingCalendars,
      currCalendars,
    );
    setCalendarEdited(enableEdit);
  };

  const handleSyncCheck = (selectedCalendar: ICalendar, syncValue: boolean) => {
    const subscriptionState = selectedCalendar.subscription_state;

    if (subscriptionState === "pending" && !checkBoxModal.calendar) {
      setCheckBoxModal({
        visibility: true,
        calendar: selectedCalendar,
      });
      return;
    }

    const syncStatus = syncValue ? "enabled" : "disabled";

    const updatedData = existingCalendars.map((calendar) => {
      if (calendar.calendar_id === selectedCalendar.calendar_id) {
        return { ...calendar, subscription_state: syncStatus };
      }
      return calendar;
    });

    setExistingCalendars(updatedData);

    setExistingCalendars((prevExistingCalendars: ICalendar[]) => {
      handleChanges(prevExistingCalendars);
      subscriptionChange(prevExistingCalendars);
      return updatedData;
    });
  };

  const onDeleteAlertClose = () => {
    setDeleteAlert({
      calendar: null,
      visibility: false,
    });
  };

  const removeCalendar = (calendar?: ICalendar) => {
    const calendarToDelete = calendar ?? deleteAlert.calendar;

    const latestCalendars = existingCalendars.filter(
      (existingCalendar) =>
        existingCalendar.calendar_id !== calendarToDelete?.calendar_id,
    );

    setExistingCalendars(latestCalendars);

    setExistingCalendars((prevExistingCalendars: ICalendar[]) => {
      handleChanges(prevExistingCalendars);
      return latestCalendars;
    });

    setDeletedCalendars([...deletedCalendars, calendarToDelete]);

    onDeleteAlertClose();
  };

  const handleRemoveCalendar = (calendar: ICalendar) => {
    const storedPreference = sessionStorage.getItem(CONSTANTS.KEYS.PREFERENCE);
    if (!storedPreference) {
      setDeleteAlert({
        calendar,
        visibility: true,
      });
      return;
    }

    const preference =
      JSON.parse(storedPreference)?.deleteConfirmationForExistingCalendars;

    if (!preference) {
      setDeleteAlert({
        calendar,
        visibility: true,
      });
    } else {
      removeCalendar(calendar);
    }
  };

  const onCheckBoxModalClose = () => {
    setCheckBoxModal({
      calendar: null,
      visibility: false,
    });
  };

  // NOTE: We may use this feature in future.
  // const pauseEventSyncingInsteadOfDelete = (calendar: ICalendar) => {
  //   if (calendar) {
  //     handleSyncCheck(calendar, false);

  //     if (deleteAlert.calendar) {
  //       onDeleteAlertClose();
  //     } else if (checkBoxModal.calendar) {
  //       onCheckBoxModalClose();
  //     }
  //   }
  // };

  const removeUnsubscribedCalendar = (calendarToDelete: ICalendar) => {
    onCheckBoxModalClose();
    setDeleteAlert({
      visibility: true,
      calendar: calendarToDelete,
    });
  };

  useEffect(() => {
    const source = sessionStorage.getItem(CONSTANTS.KEYS.SOURCE);
    if (!source || source === "app") {
      setSourceMobile(true);
    }
  }, []);

  useEffect(() => {
    fetchCalendars();
  }, [calendarSaved]);

  return (
    <>
      <Box py={4}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <CalendarListSkeleton />
        ) : existingCalendars?.length > 0 ? (
          <Box>
            {!sourceMobile && (
              <Text fontSize="lg" as="b" display="block" mb={6}>
                Your Calendars
              </Text>
            )}
            <VStack align="stretch" spacing={6}>
              {existingCalendars.slice(0, showLimit).map((calendar, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index}>
                  <Flex gap={4} alignItems="flex-start">
                    <Checkbox
                      size="lg"
                      colorScheme="brand"
                      mt={2}
                      isChecked={calendar.subscription_state === "enabled"}
                      onChange={(e) =>
                        handleSyncCheck(calendar, e.target.checked)
                      }
                    />
                    <VStack align="stretch" gap={1} overflow="hidden">
                      <Text className="calendar-name">
                        {calendar.calendar_data.name ?? "Unnamed Calendar"}
                      </Text>
                      {calendar.calendar_data?.source && (
                        <Text
                          fontSize="xs"
                          display="flex"
                          alignItems="center"
                          color="gray.500"
                        >
                          <Image
                            boxSize="14px"
                            objectFit="cover"
                            src={
                              calendar.type === "outlook"
                                ? outlookIcon
                                : googleIcon
                            }
                            alt="calendar source"
                            display="inline-block"
                            marginRight={2}
                          />
                          {calendar.calendar_data?.isShared === "true" &&
                            "Shared with "}
                          {calendar.calendar_data?.source}
                        </Text>
                      )}
                    </VStack>
                  </Flex>
                  <Spacer />
                  <IconButton
                    colorScheme="brand.100"
                    aria-label="Send email"
                    icon={<Trash2 color={CONSTANTS.PRIMARY_COLOR} />}
                    onClick={() => handleRemoveCalendar(calendar)}
                  />
                </Flex>
              ))}
              <Box mt={4}>
                {showLimit < existingCalendars.length && (
                  <Button
                    leftIcon={<ChevronDown />}
                    variant="link"
                    onClick={viewMoreCalendars}
                  >
                    View {existingCalendars.length - showLimit} more existing
                    calendars
                  </Button>
                )}
                {existingCalendars.length > 5 &&
                  showLimit >= existingCalendars.length && (
                    <Button
                      leftIcon={<ChevronUp />}
                      variant="link"
                      onClick={viewLessCalendars}
                    >
                      View less
                    </Button>
                  )}
              </Box>
            </VStack>
          </Box>
        ) : (
          <NoData />
        )}
      </Box>

      <DeleteAlert
        alert={deleteAlert}
        cancelRef={cancelRef}
        removeCalendar={removeCalendar}
        onClose={onDeleteAlertClose}
      />
      <CheckBoxModal
        modal={checkBoxModal}
        onCheckBoxModalClose={onCheckBoxModalClose}
        removeCalendar={removeUnsubscribedCalendar}
      />
    </>
  );
};

export default ExistingCalendars;
