import { ICalendar } from "../types/calendarTypes";

export default function checkForCalendarChanges(
  savedExistingCalendars: ICalendar[],
  existingCalendars: ICalendar[],
) {
  const prevCalendars = [...savedExistingCalendars];
  const currCalendars = [...existingCalendars];

  if (prevCalendars.length !== currCalendars.length) {
    return true;
  }

  const prevCalendarIds = prevCalendars.map(
    (calendar: ICalendar) => calendar.calendar_id,
  );
  const currCalendarIds = currCalendars.map(
    (calendar: ICalendar) => calendar.calendar_id,
  );

  if (prevCalendarIds.some((id) => !currCalendarIds.includes(id))) {
    return true;
  }

  return prevCalendars.some((prevCalendar) => {
    const currCalendar = currCalendars.find(
      (calendar) => calendar.calendar_id === prevCalendar.calendar_id,
    );

    return (
      currCalendar &&
      prevCalendar.subscription_state !== currCalendar.subscription_state
    );
  });
}
