import {
  Box,
  Button,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HelpCircle } from "react-feather";
import guideImage from "../assets/guide-image.png";
import TipsImage from "../assets/tips.svg";

const Guide = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Button
        leftIcon={<HelpCircle size="16px" />}
        colorScheme="facebook"
        variant="ghost"
        onClick={onOpen}
      >
        Help
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", lg: "md" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Help</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={guideImage} alt="guide" marginBottom={2} />
            <Text fontSize="sm" marginBottom={3}>
              When you select the checkbox, tweelin subscribes to your calendar
              for any future updates. You can stop the subscription or choose to
              remove the calendar anytime.
            </Text>
            <Box display="flex" alignItems="flex-start" gap={2}>
              <Image
                boxSize="20px"
                objectFit="cover"
                src={TipsImage}
                alt="Tips"
                opacity="0.4"
              />
              <Text fontSize="xs" color="gray.500" marginBottom={1}>
                Tweelin does not read any event data exept from the event
                timings and participant count. Your data is safe with us. Read
                our{" "}
                <Link
                  href="https://tweelin.com/privacy-policy/"
                  isExternal
                  color="brand.500"
                >
                  privacy policy
                </Link>{" "}
                to know more.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="solid" onClick={onClose}>
              I Understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Guide;
