import theme from "./theme";

const RedirectUri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/home"
    : "https://calendar.tweelin.com/home";

const CONSTANTS = {
  PRIMARY_COLOR: theme.colors.brand["500"],
  REDIRECT_URI: RedirectUri,
  KEYS: {
    CURRENT_CALENDAR: "CURRENT_CALENDAR",
    USER_ID_TOKEN: "USER_ID_TOKEN",
    NEW_CALENDARS: "NEW_CALENDARS",
    EXISTING_CALENDARS: "EXISTING_CALENDARS",
    PREFERENCE: "PREFERENCE",
    SOURCE: "SOURCE",
    USER_EMAIL: "USER_EMAIL",
  },
  MICROSOFT: {
    AUTHORIZATION_URL:
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    CALENDAR_END_POINT: "https://graph.microsoft.com/v1.0/me/calendars",
    INFO_END_POINT: "https://graph.microsoft.com/v1.0/me",
  },
  GOOGLE: {
    AUTHORIZATION_URL: "https://accounts.google.com/o/oauth2/v2/auth",
    CALENDAR_END_POINT:
      "https://www.googleapis.com/calendar/v3/users/me/calendarList",
  },
  TWEELIN_API: {
    TEAMS_STAGING:
      "https://dtcpwsa09h.execute-api.us-east-1.amazonaws.com/staging",
    TEAMS_PRODUCTION:
      "https://amcxru9360.execute-api.us-east-1.amazonaws.com/production",
    STAGING: "https://j64ajnq40h.execute-api.us-east-1.amazonaws.com/staging",
    PRODUCTION: "https://mfl62n07y1.execute-api.us-east-1.amazonaws.com/prod",
    CALENDAR: "/calendar",
    FETCH_MICROSOFT_TOKEN: "/token/outlook",
    FETCH_GOOGLE_TOKEN: "/token/google",
  },
  API_RESPONSE: {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
  },
};

export default CONSTANTS;
