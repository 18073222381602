import { INewCalendar } from "../types/calendarTypes";

const formatNewCalendars = (newCalendars: INewCalendar[]) => {
  const transformedData = newCalendars.map((calendar: INewCalendar) => {
    if (calendar.subscription_state === "enabled") {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { subscription_state, ...rest } = calendar;
      return rest;
    }

    if (calendar.subscription_state === "disabled") {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { subscription_state, ...rest } = calendar;
      return {
        ...rest,
        dont_subscribe: true,
      };
    }

    return calendar;
  });

  return transformedData;
};

export default formatNewCalendars;
