import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UnauthorizedAccess from "../../components/UnauthorizedAccess";
import CONSTANTS from "../../configs/constatnts";

const Unauthorized = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    if (isLoggedIn) {
      navigate("/home");
    }
  }, []);

  return (
    <div>
      <UnauthorizedAccess />
    </div>
  );
};

export default Unauthorized;
