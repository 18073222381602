import { Container, Heading, Image, Link, Text } from "@chakra-ui/react";
import LockImage from "../assets/lock.webp";

const UnauthorizedAccess = () => {
  return (
    <Container textAlign="center" pt={6}>
      <Image
        src={LockImage}
        alt="unauthorized access"
        maxW="200px"
        margin="auto"
      />
      <Heading as="h1" size="lg" noOfLines={1} mb={1}>
        Unauthorized Access
      </Heading>
      <Text>You do not have the access to reach the page content.</Text>
      <Text size="xs" color="GrayText" mt={4}>
        Reach us{" "}
        <Link color="brand.500" href="https://tweelin.com/support/">
          here
        </Link>{" "}
        for any support/help.
      </Text>
    </Container>
  );
};

export default UnauthorizedAccess;
