import React, { useContext, useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Image,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { XSquare } from "react-feather";
import CONSTANTS from "../../../configs/constatnts";
import { CalendarContext } from "../../../context/CalendarContext";
import {
  handlePreference,
  saveNewCalendars,
} from "../../../services/commonServices";
import outlookIcon from "../../../assets/outlook.png";
import googleIcon from "../../../assets/google.svg";
import { INewCalendar } from "../../../types/calendarTypes";
import Guide from "../../../components/Guide";

const NewCalendars = () => {
  const { newCalendars, setNewCalendars } = useContext(CalendarContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [calendarIdToDelete, setCalendarIdToDelete] = useState<string | null>(
    null,
  );
  const [prevCalendarCount, setPrevCalendarCount] = useState<number>(0);
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [subscriptionUnCheckAlert, setSubscriptionUnCheckAlert] =
    useState<boolean>(false);

  const handleSyncCheck = (
    selectedCalendar: INewCalendar,
    syncValue: boolean,
  ) => {
    const syncStatus = syncValue ? "enabled" : "disabled";

    const updatedData = newCalendars.map((calendar) => {
      if (calendar.calendar_id === selectedCalendar.calendar_id) {
        return { ...calendar, subscription_state: syncStatus };
      }
      return calendar;
    });

    setNewCalendars(updatedData);
  };

  const handleOnClose = () => {
    onClose();
    setCalendarIdToDelete(null);
  };

  const handleCloseSubscriptionAlert = () => {
    setSubscriptionUnCheckAlert(false);
  };

  const removeCalendar = () => {
    const latestCalendars = newCalendars.filter(
      (calendar) => calendar.calendar_id !== calendarIdToDelete,
    );

    setNewCalendars(latestCalendars);
    saveNewCalendars(latestCalendars);
    handleOnClose();
  };

  const handleRemoveCalendar = (calendarId: string) => {
    setCalendarIdToDelete(calendarId);
  };

  const handleSubscriptionUnderstanding = () => {
    sessionStorage.setItem("SUBSCRIPTION_UNDERSTANDING", "true");
    handleCloseSubscriptionAlert();
  };

  useEffect(() => {
    console.log(calendarIdToDelete);
    if (!calendarIdToDelete) {
      return;
    }

    const storedPreference = sessionStorage.getItem(CONSTANTS.KEYS.PREFERENCE);

    if (!storedPreference) {
      onOpen();
      return;
    }

    const preference =
      JSON.parse(storedPreference)?.deleteConfirmationForNewCalendars;

    if (!preference) {
      onOpen();
    } else {
      removeCalendar();
    }
  }, [calendarIdToDelete]);

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 500);
  };

  useEffect(() => {
    if (newCalendars.length > prevCalendarCount) {
      scrollToBottom();
    }
    setPrevCalendarCount(newCalendars.length);
  }, [newCalendars]);

  return (
    <>
      <Box py={4}>
        {newCalendars.length > 0 && (
          <Box>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text fontSize="lg" as="b" display="block">
                You are adding
              </Text>
              <Guide />
            </Flex>
            {/* <Text fontSize="sm" mb={6} color="gray.500">
              These calendars will be added to tweelin once you save
            </Text> */}
            <VStack align="stretch" spacing={6}>
              {newCalendars.map((calendar, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index}>
                  <Flex gap={4} alignItems="flex-start">
                    <Checkbox
                      size="lg"
                      colorScheme="brand"
                      mt={2}
                      isChecked={calendar.subscription_state === "enabled"}
                      onChange={(e) => {
                        const understanding = sessionStorage.getItem(
                          "SUBSCRIPTION_UNDERSTANDING",
                        );
                        if (!understanding || understanding !== "true") {
                          setSubscriptionUnCheckAlert(true);
                        }
                        handleSyncCheck(calendar, e.target.checked);
                      }}
                    />
                    <VStack align="stretch" gap={1}>
                      <Text className="calendar-name">
                        {calendar.calendar_data.name}
                      </Text>
                      <Text
                        fontSize="xs"
                        display="flex"
                        alignItems="center"
                        color="gray.500"
                      >
                        <Image
                          boxSize="14px"
                          objectFit="cover"
                          src={
                            calendar.type === "outlook"
                              ? outlookIcon
                              : googleIcon
                          }
                          alt="calendar source"
                          display="inline-block"
                          marginRight={2}
                        />
                        {calendar.calendar_data.isShared === "true" &&
                          "Shared with "}
                        {calendar.calendar_data.source}
                      </Text>
                    </VStack>
                  </Flex>
                  <Spacer />
                  <IconButton
                    colorScheme="brand.100"
                    aria-label="Send email"
                    icon={<XSquare color={CONSTANTS.PRIMARY_COLOR} />}
                    onClick={() => handleRemoveCalendar(calendar.calendar_id)}
                  />
                </Flex>
              ))}
            </VStack>
            <Divider mt={6} />
          </Box>
        )}
      </Box>

      <AlertDialog
        isOpen={subscriptionUnCheckAlert}
        leastDestructiveRef={cancelRef}
        onClose={handleCloseSubscriptionAlert}
        isCentered
        size={{ base: "xs", lg: "md" }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Tips
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                By unchecking a calendar, you keep it in the list but Tweelin
                does not consider its events when finding the right moment to
                talk.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={() => handleSubscriptionUnderstanding()}
                width="100%"
              >
                I understand
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleOnClose}
        isCentered
        size={{ base: "xs", lg: "md" }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Calendar
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Are you sure? You can not revert this action.</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <VStack width="100%">
                <Button
                  colorScheme="brand"
                  onClick={() => removeCalendar()}
                  width="100%"
                >
                  Remove Calendar
                </Button>
                <Button ref={cancelRef} onClick={handleOnClose} width="100%">
                  Cancel
                </Button>
              </VStack>
            </AlertDialogFooter>
            <Flex justify="center" pb={4}>
              <Checkbox
                size="sm"
                colorScheme="brand"
                onChange={(e) =>
                  handlePreference(
                    "deleteConfirmationForNewCalendars",
                    e.target.checked,
                  )
                }
              >
                Don&apos;t ask me again for this session
              </Checkbox>
            </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default NewCalendars;
